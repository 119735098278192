import { checkPermissionList } from '@shared/utils/permissionUtils.mjs';
import { checkPokerOk } from '@/utils/baseUtil';
import { baseList, langList, siteIds } from '@/constants/base/siteMap';
import { cdnImgPath } from '@shared/utils/cdnUtils.mjs';

const guideUrls = {
  GGPUKE: { label: 'GG扑克', name: 'GGPuke.com', local: 'ggpuke.com', dr: 'ggpuke.com', test: 'ggpuke.com', stage: 'ggpuke.com', production: 'ggpuke.com' },
  NATURAL8: { label: 'Natural8', name: 'Natural8.com', local: 'natural8.com', dr: 'natural8.com', test: 'natural8.com', stage: 'natural8.com', production: 'natural8.com' },
  GGPOK: { label: 'PokerOK', name: 'PokerOK.com', local: 'gg.devpokerok.wpengine.com', dr: 'gg.devpokerok.wpengine.com', test: 'gg.devpokerok.wpengine.com', stage: 'gg.stgpokerok.wpengine.com', production: 'pokerok750.com' },
  GGPCOM: { label: 'GGPoker', name: 'GGPoker.com', local: 'en.devggpokerx3.wpengine.com', dr: 'en.devggpokerx3.wpengine.com', test: 'en.devggpokerx3.wpengine.com', stage: 'en.stgggpokerx3.wpengine.com', production: 'ggpoker.com' },
  GGPUK: { label: 'GGPokerUK', name: 'GGPoker.co.uk', local: 'devggpokeruk.wpengine.com', dr: 'devggpokeruk.wpengine.com', test: 'devggpokeruk.wpengine.com', stage: 'stgggpokeruk.wpengine.com', production: 'ggpoker.co.uk' },
  GGPEU: { label: 'GGPokerEU', name: 'GGPoker.eu', local: 'en.devggpokereuro.wpengine.com', dr: 'en.devggpokereuro.wpengine.com', test: 'en.devggpokereuro.wpengine.com', stage: 'en.stgggpokereuro.wpengine.com', production: 'en.ggpoker.eu' },
  GGPFI: { label: 'GGPokerFI', name: 'fi.GGPoker.eu', local: 'fi.devggpokereuro.wpengine.com', dr: 'fi.devggpokereuro.wpengine.com', test: 'fi.devggpokereuro.wpengine.com', stage: 'fi.stgggpokereuro.wpengine.com', production: 'fi.ggpoker.eu' },
  GGPPL: { label: 'GGPokerPL', name: 'pl.GGPoker.eu', local: 'pl.devggpokereuro.wpengine.com', dr: 'pl.devggpokereuro.wpengine.com', test: 'pl.devggpokereuro.wpengine.com', stage: 'pl.stgggpokereuro.wpengine.com', production: 'pl1.ggpoker.com' },
  GGPDE: { label: 'GGPokerDE', name: 'GGPoker.de', local: 'devggpokerde.wpengine.com', dr: 'devggpokerde.wpengine.com', test: 'devggpokerde.wpengine.com', stage: 'stgggpokerde.wpengine.com', production: 'ggpoker.de' },
  GGPBE: { label: 'GGPokerBE', name: 'GGPoker.be', local: 'devggpokerbe.wpengine.com', dr: 'devggpokerbe.wpengine.com', test: 'devggpokerbe.wpengine.com', stage: 'stgggpokerbe.wpengine.com', production: 'ggpoker.be' },
  GGPHU: { label: 'GGPokerHU', name: 'hun.GGPoker.eu', local: 'hu.devggpokereuro.wpengine.com', dr: 'hu.devggpokereuro.wpengine.com', test: 'hu.devggpokereuro.wpengine.com', stage: 'hu.stgggpokereuro.wpengine.com', production: 'hun.ggpoker.eu' },
  GGPNL: { label: 'GGPokerNL', name: 'GGPoker.nl', local: 'devggpokernl.wpengine.com', dr: 'devggpokernl.wpengine.com', test: 'devggpokernl.wpengine.com', stage: 'stgggpokernl.wpengine.com', production: 'ggpoker.nl' },
  GGPRO: { label: 'PlayGG', name: 'PlayGG.ro', local: 'devggpokerro.wpengine.com', dr: 'devggpokerro.wpengine.com', test: 'devggpokerro.wpengine.com', stage: 'stgggpokerro2.wpengine.com', production: 'playgg.ro' },
  GGPBR: { label: 'GGPokerBR', name: 'br.GGPoker.com', local: 'br.devggpokerx3.wpengine.com', dr: 'br.devggpokerx3.wpengine.com', test: 'br.devggpokerx3.wpengine.com', stage: 'br.stgggpokerx3.wpengine.com', production: 'br.ggpoker.com' },
  GGPUA: { label: 'GGPokerUA', name: 'ua.ggpoker.com', local: 'ua.devggpokerx3.wpengine.com', dr: 'ua.devggpokerx3.wpengine.com', test: 'ua.devggpokerx3.wpengine.com', stage: 'ua.stgggpokerx3.wpengine.com', production: 'https://ua.ggpoker.com' },
  OLYBET: { label: 'OlyBet', name: 'OlyBet.ee', local: 'www.olybet.ee/gg-poker?lang=en', dr: 'www.olybet.ee/gg-poker?lang=en', test: 'www.olybet.ee/gg-poker?lang=en', stage: 'www.olybet.ee/gg-poker?lang=en', production: 'www.olybet.ee/gg-poker?lang=en' },
};

const blockedCountry = {
  GGPCOM: [{ country: 'BE', link: guideUrls.GGPBE }, { country: 'FI', link: guideUrls.GGPFI }, { country: 'DE', link: guideUrls.GGPDE }, { country: 'MT', link: guideUrls.GGPEU },
    { country: 'NL', link: guideUrls.GGPNL }, { country: 'RO', link: guideUrls.GGPRO }, { country: 'SE', link: guideUrls.GGPEU }, { country: 'GB', link: guideUrls.GGPUK }
  ],
  GGPCW: [{ country: 'AL', link: guideUrls.GGPCOM }, { country: 'AD', link: guideUrls.GGPCOM }, { country: 'AO', link: guideUrls.GGPCOM }, { country: 'AR', link: guideUrls.GGPCOM }, { country: 'AT', link: guideUrls.GGPCOM },
    { country: 'BD', link: guideUrls.GGPCOM }, { country: 'BE', link: guideUrls.GGPBE }, { country: 'BO', link: guideUrls.GGPCOM }, { country: 'BR', link: guideUrls.GGPBR }, { country: 'CM', link: guideUrls.GGPCOM },
    { country: 'CA', link: guideUrls.GGPCOM }, { country: 'CL', link: guideUrls.GGPCOM }, { country: 'CR', link: guideUrls.GGPCOM }, { country: 'HR', link: guideUrls.GGPCOM }, { country: 'CY', link: guideUrls.GGPCOM },
    { country: 'DO', link: guideUrls.GGPCOM }, { country: 'EC', link: guideUrls.GGPCOM }, { country: 'EG', link: guideUrls.GGPCOM }, { country: 'SV', link: guideUrls.GGPCOM }, { country: 'FI', link: guideUrls.GGPFI },
    { country: 'DE', link: guideUrls.GGPDE }, { country: 'GH', link: guideUrls.GGPCOM }, { country: 'GI', link: guideUrls.GGPCOM }, { country: 'GT', link: guideUrls.GGPCOM }, { country: 'GG', link: guideUrls.GGPCOM },
    { country: 'GN', link: guideUrls.GGPCOM }, { country: 'HN', link: guideUrls.GGPCOM }, { country: 'HU', link: guideUrls.GGPHU }, { country: 'IS', link: guideUrls.GGPCOM }, { country: 'ID', link: guideUrls.GGPCOM },
    { country: 'IR', link: guideUrls.GGPCOM }, { country: 'ID', link: guideUrls.GGPCOM }, { country: 'IE', link: guideUrls.GGPCOM }, { country: 'IM', link: guideUrls.GGPCOM }, { country: 'JM', link: guideUrls.GGPCOM },
    { country: 'JP', link: guideUrls.GGPCOM }, { country: 'JE', link: guideUrls.GGPCOM }, { country: 'KE', link: guideUrls.GGPCOM }, { country: 'XK', link: guideUrls.GGPCOM }, { country: 'KW', link: guideUrls.GGPCOM },
    { country: 'LB', link: guideUrls.GGPCOM }, { country: 'LI', link: guideUrls.GGPCOM }, { country: 'LU', link: guideUrls.GGPCOM }, { country: 'MG', link: guideUrls.GGPCOM }, { country: 'MY', link: guideUrls.GGPCOM },
    { country: 'MV', link: guideUrls.GGPCOM }, { country: 'MT', link: guideUrls.GGPEU }, { country: 'MU', link: guideUrls.GGPCOM }, { country: 'MX', link: guideUrls.GGPCOM }, { country: 'MC', link: guideUrls.GGPCOM },
    { country: 'MN', link: guideUrls.GGPCOM }, { country: 'ME', link: guideUrls.GGPCOM }, { country: 'MA', link: guideUrls.GGPCOM }, { country: 'NL', link: guideUrls.GGPNL }, { country: 'NZ', link: guideUrls.GGPCOM },
    { country: 'NG', link: guideUrls.GGPCOM }, { country: 'MK', link: guideUrls.GGPCOM }, { country: 'NO', link: guideUrls.GGPCOM }, { country: 'PA', link: guideUrls.GGPCOM }, { country: 'PY', link: guideUrls.GGPCOM },
    { country: 'PE', link: guideUrls.GGPCOM }, { country: 'PH', link: guideUrls.GGPCOM }, { country: 'PL', link: guideUrls.GGPPL }, { country: 'QA', link: guideUrls.GGPCOM }, { country: 'RO', link: guideUrls.GGPRO },
    { country: 'SN', link: guideUrls.GGPCOM }, { country: 'SK', link: guideUrls.GGPCOM }, { country: 'SI', link: guideUrls.GGPCOM }, { country: 'ZA', link: guideUrls.GGPCOM }, { country: 'LK', link: guideUrls.GGPCOM },
    { country: 'TZ', link: guideUrls.GGPCOM }, { country: 'TT', link: guideUrls.GGPCOM }, { country: 'UG', link: guideUrls.GGPCOM }, { country: 'UA', link: guideUrls.GGPUA }, { country: 'AE', link: guideUrls.GGPCOM },
    { country: 'GB', link: guideUrls.GGPUK }, { country: 'UY', link: guideUrls.GGPCOM }, { country: 'VE', link: guideUrls.GGPCOM }
  ],
  // GGPJP: [{ country: 'JP', link: guideUrls.GGPCOM }],
  // GGPNG: [{ country: 'NG', link: guideUrls.GGPCOM }],
  GGPEU: [{ country: 'AD', link: guideUrls.GGPCOM }, { country: 'AT', link: guideUrls.GGPCOM }, { country: 'BE', link: guideUrls.GGPBE }, { country: 'HR', link: guideUrls.GGPCOM }, { country: 'CY', link: guideUrls.GGPCOM },
    { country: 'EE', link: guideUrls.OLYBET }, { country: 'FI', link: guideUrls.GGPFI }, { country: 'DE', link: guideUrls.GGPDE }, { country: 'HU', link: guideUrls.GGPHU }, { country: 'IS', link: guideUrls.GGPCOM },
    { country: 'LV', link: guideUrls.OLYBET }, { country: 'LI', link: guideUrls.GGPCOM }, { country: 'LU', link: guideUrls.GGPCOM }, { country: 'MC', link: guideUrls.GGPCOM }, { country: 'NL', link: guideUrls.GGPNL },
    { country: 'NO', link: guideUrls.GGPCOM }, { country: 'PL', link: guideUrls.GGPPL }, { country: 'RO', link: guideUrls.GGPRO }, { country: 'SK', link: guideUrls.GGPCOM }, { country: 'SI', link: guideUrls.GGPCOM },
  ],
};

const SignUpPersonalInfo = 'SignUpPersonalInfo';
const SignUpTnc = 'SignUpTnc';
const SignUpVerify = 'SignUpVerify';
const SignUpHighestPassword = 'SignUpHighestPassword';
const SignUpComplete = 'SignUpComplete';
export const routeNames = { SignUpPersonalInfo, SignUpTnc, SignUpVerify, SignUpHighestPassword, SignUpComplete };
const baseRoute = Object.keys(routeNames).filter(r => r !== routeNames.SignUpHighestPassword);
const routeExcludeTnC = [...baseRoute]; // ggpassSiteMap에서 사용중이므로 우선 유지하나 차후 제거 필요
routeExcludeTnC.splice(1, 1);

const isProd = process.env.VUE_APP_ENV === 'production';
export const getSiteId = siteId => (siteId !== siteIds.POKERARABIA && siteId ? siteId.toUpperCase() : siteId);

const getMergedList = npOnly => {
  const list = {
    [siteIds.GGPUKE]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute, theme: 'GOLD', captcha: 'geetest' },
    [siteIds.EVPUKE]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute, captcha: 'geetest' },
    [siteIds.GGPUK]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPOK]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.TWOACE]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.SEVENXL]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.DAVAOPOKER]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.NATURAL8]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.N8IN]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPCOM]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPEU]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPFI]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPPL]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPDE]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPBE]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPHU]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPNL]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPRO]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPBR]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPUA]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPCW]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPJP]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPNG]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPCZ]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPPH]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGPDK]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.WSOPON]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.OCNP]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.POKERARABIA]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
    [siteIds.GGVEGAS]: { routeNames: npOnly ? [SignUpTnc, SignUpHighestPassword] : baseRoute },
  };
  Object.keys(list).forEach(o => {
    const t = baseList.find(v => o === v.site);
    list[o] = { ...list[o], ...t };
  });
  return list;
};

export const defaultSiteMap = ({ domain, site, useBlock, npOnly }) => {
  domain = domain.split(':')[0];
  site = getSiteId(site);

  const list = getMergedList(npOnly);
  const env = process.env.VUE_APP_ENV;
  const isLocal = domain.includes('localhost') || env === 'local';
  let selected = getSite({ domain, site, npOnly });

  if (!isLocal && site?.toLowerCase() !== 'none') selected = list[site] || selected;

  if (selected) {
    if (useBlock && site && blockedCountry[selected.site]) {
      selected.blockList = blockedCountry[selected.site];
    }
    let languageList = [...langList];
    selected.langList = checkPermissionList(languageList, selected.site);
  }
  return selected;
};

export const getFixedSiteInfo = ({ site, npOnly }) => {
  const list = getMergedList(npOnly);
  site = getSiteId(site);
  const fixed = list[site];
  fixed.langList = checkPermissionList(langList, fixed.site);
  fixed.blockList = blockedCountry[fixed.site];

  return list[site];
};

export const npOnlySiteMap = ({ domain, site }) => {
  const selected = getSite({ domain, site, npOnly: true });
  selected.langList = checkPermissionList(langList, selected.site);
  selected.routeNames = [SignUpTnc];
  return selected;
};

export const ggpassSiteMap = domain => ({
  domain: 'ggpass',
  // api: baseInfo.VUE_APP_API_GATEWAY,
  logo: `${cdnImgPath}/ggpuke.png`,
  favicon: `${cdnImgPath}/ggpuke_favicon.ico`,
  title: 'GG扑克',
  routeNames: routeExcludeTnC,
});

export const getSite = ({ domain, site, npOnly }) => {
  const list = getMergedList(npOnly);
  site = getSiteId(site);
  let selected = checkPokerOk(domain, list[siteIds.GGPOK]);
  if (!selected) {
    const isLocal = domain.includes('localhost') || process.env.VUE_APP_ENV === 'local';
    if (isLocal) selected = list[site];
    else {
      const includesDomain = (list, key, domain) => {
        const isCasino = domain.includes('casino');
        return (isCasino ? list[key].domain.casino : (list[key].domain.default || ''))?.split(',').find(i => domain.includes(i));
      };
      selected = list[Object.keys(list).find(key => includesDomain(list, key, domain))];
    }
  }
  return selected;
};
