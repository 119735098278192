<template>
  <div signup-form v-if="modelInfo" :name="gtmTag" :class="gtmClass">
    <template v-if="ready">
      <VerifyMobileNumber v-if="!isEmailForm" :structure="structure" :model-info="modelInfo" :label-color-gray="false" />
      <EmailSearchSelect v-else :structure="structure" :model-info="modelInfo" />
      <ValidationPassword :config="config" :immediate="immediate" :label="$t('signUp.personalInfo.passwordMinMax',{min, max})" name="_password" :rules="pwRules" v-model="modelInfo.Password" :preset="{placeholder:null, maxLength: max}" :label-color-gray="false" />
      <BonusCodeInput v-model="modelInfo.BonusCode" v-if="!unusedBonusCode" />
      <ChannelingCodeInput v-model="modelInfo.BonusCode" v-if="showChannelingCode" />
      <div v-if="showToggleButton" class="button-holder">
        <button @click="toggle">{{ $t(createButtonName) }}</button>
        <FontIcon name="angle-right" />
      </div>
    </template>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { emailPasswordValidation, mobilePasswordValidation } from '@/utils/pwUtil';
import Specific from '@shared/types/Specific';
import PasswordInput from '@shared/components/common/input/PasswordInput.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import BonusCodeInput from '@/views/components/pages/sign-up/BonusCodeInput.vue';
import { siteIds } from '@/constants/base/siteMap';
import FontIcon from '@shared/components/common/FontIcon.vue';
import VerifyMobileNumber from '@/views/components/pages/sign-up/VerifyMobileNumber.vue';
import EmailSearchInputSelect from '@/views/components/common/EmailSearchInputSelect.vue';
import SearchDropSelect from '@/views/components/common/SearchDropSelect.vue';
import EmailSearchSelect from '@/views/components/common/EmailSearchSelect.vue';
import ValidationPassword from '@/views/components/common/ValidationPassword.vue';
import ChannelingCodeInput from '@/views/components/pages/sign-up/ChannelingCodeInput.vue';

export default {
  name: 'SignupForm',
  components: { ChannelingCodeInput, ValidationPassword, EmailSearchSelect, FontIcon, VerifyMobileNumber, BonusCodeInput },
  props: {
    modelInfo: { type: Specific, default: null },
    structure: { type: Specific, default: null },
  },
  data() {
    return {
      ready: false,
      list: null,
      isEmailForm: true,
      showToggleButton: false,
      immediate: false,
      TextInput,
      PasswordInput,
      SearchDropSelect,
      EmailSearchInputSelect,
    };
  },
  watch: {
    signedEmail(v) {
      if (this.brandSignup) this.modelInfo.Email = v;
    },
    signedPw(v) {
      if (this.brandSignup) this.modelInfo.Password = v;
    }
  },
  computed: {
    env: state('env', 'env'),
    site: state('env', 'site'),
    brandSignup: state('env', 'brandSignup'),
    skipSignupPersonalInfo: state('env', 'skipSignupPersonalInfo'),
    config: state('config', 'info'),
    invite: state('query', 'invite'),
    channelingName: state('query', 'channelingName'),
    signedPw: state('user', 'signedPw'),
    signedEmail: state('user', 'signedEmail'),
    regionKey: state('query', 'regionKey'),
    channelingRegionList : state('query', 'channelingRegionList'),
    qtag: state('query', 'qtag'),

    sendVerificationType() {
      return this.modelInfo.SendVerificationType;
    },
    min() {
      return this.config?.passwordMinLength || 8;
    },
    max() {
      return this.config?.passwordMaxLength || 20;
    },
    gtmTag() {
      return 'Step3_Email_Password_View';
    },
    gtmClass() {
      return 'signup-email-password-view';
    },
    isDavao() {
      return this.site === siteIds.DAVAOPOKER;
    },
    isMbp() {
      return [siteIds.EVPUKE, siteIds.GGPUKE].includes(this.site);
    },
    unusedBonusCode() {
      return this.isDavao || (this.isMbp && this.invite) || this.channelingName;
    },
    showChannelingCode() {
      if(!this.channelingRegionList?.length) return false;
      return this.channelingName && this.channelingRegionList.includes(this.site);
    },
    mobileValue() {
      return this.modelInfo.MobileNumber || '';
    },
    emailValue() {
      return this.modelInfo.Email || '';
    },
    pwRules() {
      if (!this.config) return '';
      return this.isEmailForm ? emailPasswordValidation(this.config, this.modelInfo.Email) : mobilePasswordValidation(this.config, this.modelInfo.MobileNumber);
    },
    createButtonName() {
      return this.isEmailForm ? 'signUp.personalInfo.createMobile' : 'signUp.personalInfo.createEmail';
    },
  },
  methods: {
    async initialize() {
      this.isEmailForm = this.config.loginType !== 'MOBILE';
      this.showToggleButton = this.config.loginType === 'BOTH_EMAIL_MOBILE';
      if (this.brandSignup) {
        this.immediate = true;
        if (!this.skipSignupPersonalInfo) {
          this.modelInfo.Email = this.signedEmail;
          this.modelInfo.Password = this.signedPw;
          this.ready = true;
        } else {
          let interval = setInterval(() => {
            if (this.modelInfo.Email && this.modelInfo.Password) {
              window.clearInterval(interval);
              interval = null;
              this.ready = true;
            }
          }, 500);
        }
      } else this.ready = true;

      this.setChanneling();
    },
    async toggle() {
      this.isEmailForm = !this.isEmailForm;
      this.modelInfo.Email = null;
      this.modelInfo.MobileCountryCode = null;
      this.modelInfo.MobileNumber = null;
      this.modelInfo.Password = null;

      this.modelInfo.SendVerificationType = this.isEmailForm ? 'EMAIL' : 'MOBILE';
    },
    setChanneling() {
      if(!this.showChannelingCode) return;
      this.modelInfo.BonusCode = this.qtag ? this.qtag : this.channelingName;
    }
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[signup-form] {
  > * {
    &:not(:nth-of-type(1)) { .mt(8);}
  }
  > .button-holder { .mt(100); .flex-center();
    > button { .mr(4); .c(@c-text-desc); .-b(@c-text-desc); }
    > [font-icon] { .fs(16); .c(@c-blurRed); }
  }
  > [verify-mobile-number] {
    > .field-holder { .pt(0); }
  }
}
</style>