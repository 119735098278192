<template>
  <form input-change-password>
    <!--    <InputComponent :label="$t('_.currentPassword')" name="_oldPassword" :component="PasswordInput" :rules="pwRules" v-model="model.OldPassword" :preset="currentPasswordPreset" :error-msg="error.OldPassword" />-->
    <ValidationPassword v-if="isPasswordSet" :config="pwConfig" :label="$t('_.currentPassword')" theme="white" name="_oldPassword" :rules="pwRules" v-model="model.OldPassword" :preset="currentPasswordPreset" :error-msg="$t(error.OldPassword)" />
    <ValidationPassword :config="pwConfig" :label="$t('_.newPassword')" theme="white" name="_password" :rules="pwRules" v-model="model.Password" :preset="newPasswordPreset" :error-msg="$t(error.Password)" />
    <!--    <InputComponent :label="$t('_.newPassword')" name="_password" :component="PasswordInput" :rules="pwRules" v-model="model.Password" :preset="newPasswordPreset" :error-msg="error.Password" />-->
    <!--    <ValidationPassword :label="$t('_.newPassword')" name="_password" :rules="pwRules" v-model="model.Password" :preset="newPasswordPreset" :error-msg="error.Password" />-->
    <InputComponent :label="$t('_.confirmPassword')" :rules="confirmPasswordRules" name="_confirmPassword" :component="PasswordInput" v-model="ConfirmPassword" :preset="confirmPasswordPreset" />
  </form>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import { emailPasswordValidation } from '@/utils/pwUtil';
import Specific from '@shared/types/Specific';
import InputComponent from '@/views/components/gg-pass/InputComponent.vue';
import PasswordInput from '@shared/components/common/input/PasswordInput.vue';
import { siteIds } from '@/constants/base/siteMap';
import ValidationPassword from '@/views/components/common/ValidationPassword.vue';

export default {
  name: 'InputChangePassword',
  lexicon: 'myInfo',
  components: { ValidationPassword, InputComponent },
  props: {
    model: Specific,
    error: Specific,
    pwConfig: Specific,
    isPasswordSet: { type: Boolean, default: false, },
    disabled: { type: Boolean, default: false, },
  },
  data() {
    return {
      ConfirmPassword: null,
    };
  },
  computed: {
    PasswordInput() {
      return PasswordInput;
    },
    site: state('env', 'site'),
    config: state('config', 'info'),
    userInfo: state('user', 'userInfo'),
    onePassInfo: state('user', 'onePassInfo'),

    currentPasswordPreset() { return { placeholder: this.$t('_.currentPassword'), myinfo: true }; },
    newPasswordPreset() { return { placeholder: this.$t('_.newPassword'), myinfo: true }; },
    confirmPasswordPreset() { return { placeholder: this.$t('_.confirmPassword'), myinfo: true }; },
    isGGPDE() {
      return this.site === siteIds.GGPDE;
    },
    isGgpass() {
      return !isExcludeGgpass(this.site);
    },
    confirmPasswordRules() {
      return `required|gpComparePassword:${this.model.Password}`;
    },
    pwRules() {
      if (!this.pwConfig) return '';
      return emailPasswordValidation(this.pwConfig, this.emailValue);
    },
    emailValue() {
      return !this.isGgpass ? this.userInfo.Email : this.onePassInfo.email;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-change-password] { .bgc(@gp-bgc-gray);.p(20, 15, 20, 15); .br(16); .flex(); .flex-dc(); .gap(15); .w(100%);}
</style>