<template>
  <div fi-credential class="field-wrapper">
    <div class="content">
      <div class="header">
        <p class="desc" v-html="$t('findId.description.credentialDesc', {brand : displayBrandName, method : isEmail?$t('emailAddress') : $t('mobileNumber')})" />
      </div>
      <div class="field-holder">
        <div v-if="!isEmail" class="mobile-holder">
          <ValidationComponent :label="$t('mobileNumber')" name="_countryNumber" :component="CountryNumberDropSelect" rules="requiredSelect" v-model="countryInfo" @input="updateCountry" @focus="$emit('focus')" @blue="$emit('blue')" />
          <ValidationComponent class="number-field" name="_mobileNumber" :component="TrimTextInput" rules="required" v-model="modelInfo.MobileNumber" :preset="{phone: true, type: 'tel'}" />
          <!--          <ValidationErrors v-if="(emailValue.length && errors.length) || error" class="error-msg" :errors="errors" :error-msg="error" />-->
        </div>
        <EmailSearchSelect :model-info="modelInfo" v-else :structure="{}" rules="required" :placeholder="this.$t('emailAddressOrUsername')" :label="this.$t('emailAddressOrUsername')" />
        <ValidationComponent name="newPassword" class="pw" rules="required" v-model="modelInfo.Password" :preset="{placeholder: ''}" :component="PasswordInput" :label="$t('password')" />
        <ValidationErrors class="error-msg" :errorMsg="structure.errorMsg" />
      </div>
    </div>
    <div class="footer">
      <p class="btn-holder"><a @click="toggleType" v-html="otherType"></a>
        <FontIcon class="icon" name="angle-right" />
      </p>
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import PasswordInput from '@shared/components/common/input/PasswordInput.vue';
import { brandIds, displayBrandNames, siteIds } from '@/constants/base/siteMap';
import { state } from '@shared/utils/storeUtils.mjs';
import LimitTimer from '@/views/components/pages/on-boarding/LimitTimer.vue';
import CountryNumberDropSelect from '@/views/components/common/CountryNumberDropSelect.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import EmailSearchSelect from '@/views/components/common/EmailSearchSelect.vue';

export default {
  name: 'FiCredential',
  components: { EmailSearchSelect, FontIcon, LimitTimer, ValidationComponent, ProgressButton },
  props: {
    modelInfo: { type: Specific, default: null },
    structure: Specific,
  },
  data() {
    return {
      isEmail: false,
      countryInfo: null,
      captcha: null,
      config: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    brand: state('env', 'brand'),
    displayBrandName() {
      const key = Object.keys(brandIds).find(key => brandIds[key] === this.brand);
      return this.brandNames[key];
    },
    brandNames(){
      return displayBrandNames
    },
    PasswordInput() {
      return PasswordInput;
    },
    CountryNumberDropSelect() {
      return CountryNumberDropSelect;
    },
    TrimTextInput() {
      return TrimTextInput;
    },
    min() {
      return 8;
    },
    max() {
      return 20;
    },
    emailValue() {
      return this.modelInfo.Email || '';
    },
    pwRule() {
      const emailName = this.emailValue.split('@')[0];
      const password = this.modelInfo.Password || '';
      const contain = emailName.length && emailName.length <= password.length ? `containEmail:${this.emailValue}` : '';
      return this.isGGPDE ? `required|length:8,20|password|${contain}` : `required|length:8,20|includeNumberAndAlphabet|${contain}`;
    },
    isGGPDE() {
      return this.site === siteIds.GGPDE;
    },
    otherType() {
      return !this.isEmail ? `${this.$t('emailOrUser')}` : this.$t('mobileNumber');
    },
  },
  watch: {
    'modelInfo.Email': 'removeError',
    'modelInfo.MobileNumber': 'removeError',
    'modelInfo.CountryCode': 'removeError',
    'modelInfo.Password': 'removeError',
    'structure.errorMsg': 'setFocus'
  },
  methods: {
    setFocus() {
      if(!document.body.querySelector(':focus'))this.$el.querySelector('input').focus();
    },
    removeError() {
      this.$emit('clear-error');
    },
    async initialize() {
      this.toggleType();
    },
    updateCountry(v) {
      if (!v) return;
      this.countryInfo = v;
      if (this.countryInfo) {
        if (!this.isGgpass) this.modelInfo.CountryCode = this.countryInfo.CountryCode;
        else this.modelInfo.CountryCode = `+${this.countryInfo.PhoneCountryCode}`;
      }
    },
    toggleType() {
      this.structure.errorMsg = null;
      this.isEmail = !this.isEmail;
      this.modelInfo.RequestType = this.isEmail ? 'IdEmailOrUsername' : 'IdMobile';
      this.structure.methodType = this.isEmail ? 'Email' : 'Mobile';
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fi-credential] { .flex(); .flex-dc();
  .error-msg {.pl(2); .m(0); .c(@c-red); .fs(14); .pt(5);}
  .pw { .mt(8); }
  .field-holder {flex: 1;
    .mobile-holder { .flex(); .flex-dc(); .gap(4);
      @media (@ml-up) {
        .flex(); .flex-dr(); .space-between();
        > * {
          &:nth-of-type(1) { .w(35%); }
          &:nth-of-type(2) { .w(calc(65% - 8px)); }
        }
      }
    }
  }
  .footer { .flex(); .justify-center();
    .btn-holder {margin-bottom: 30px !important; .flex(); .justify-center(); .items-center(); .gap(5); .fs(16);
      > a {text-decoration-line: underline;}
      .icon {.c(#C64040)}
    }
  }
}
</style>